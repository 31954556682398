$ ->
  assign_datetimepicker()

assign_datetimepicker = ->
  $(".datetimepicker").each ->
    unless $(this).children("input:first").hasClass("disabled")
      $(this).datetimepicker(
        {
          todayBtn: 'linked',
          autoclose: true,
          format: "d/m/yyyy hh:ii",
          language: $('html').attr('lang')
        })
    $.fn.datetimepicker.dates['da']['today'] = 'Nu'