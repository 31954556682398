$ ->
  assign_parkees_click_actions()
  window.assign_parkees_click_actions = assign_parkees_click_actions

  assign_parkees_search_submit()
  assign_parkees_search_key_up()

  if $('body.location_parkees.show').length > 0
    assign_location_parkees_show_click_actions()
    window.assign_location_parkees_show_click_actions = assign_location_parkees_show_click_actions
    assign_location_parkees_show_search_submit()
    assign_location_parkees_show_search_key_up()

  if $("body.parkees.show").length > 0
    assign_parkees_show_click_actions()
    window.assign_parkees_show_click_actions = assign_parkees_show_click_actions
    assign_parkees_show_search_submit()
    assign_parkees_show_search_key_up()

assign_parkees_search_submit = () ->
  $('#new_parkee_search').submit(() ->
    window.parkees_request.abort() if window.parkees_request
    window.parkees_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_parkees_search_key_up = () ->
  $('#new_parkee_search input').keyup( () ->
    window.parkees_request.abort() if window.parkees_request
    window.parkees_request = $.get($('#new_parkee_search').attr('action'), $('#new_parkee_search').serialize(), null, 'script')
    false
  )

assign_parkees_click_actions = () ->
  $('#parkees th a, #parkees .pagination a, #new_parkee_search a').off('click')
  $('#parkees th a, #parkees .pagination a, #new_parkee_search a').on('click', () ->
    $.getScript(this.href)
    false
  )

assign_parkees_show_click_actions = () ->
  links = $('#registrations th a, #registrations .pagination a, #accesses th a, #accesses .pagination a, #parkees_show_search a')
  links.off('click')
  links.on('click', () ->
    $.getScript(this.href)
    false
  )

assign_parkees_show_search_submit = () ->
  $('#parkees_show_search').submit(() ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_parkees_show_search_key_up = () ->
  $('#parkees_show_search input').keyup( () ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get($('#parkees_show_search').attr('action'), $('#parkees_show_search').serialize(), null, 'script')
    false
  )

assign_location_parkees_show_click_actions = () ->
  links = $('#registrations th a, #registrations .pagination a, #accesses th a, #accesses .pagination a, #location_parkees_show_search a')
  links.off('click')
  links.on('click', () ->
    $.getScript(this.href)
    false
  )

assign_location_parkees_show_search_submit = () ->
  $('#location_parkees_show_search').submit(() ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_location_parkees_show_search_key_up = () ->
  $('#location_parkees_show_search input').keyup( () ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get($('#location_parkees_show_search').attr('action'), $('#location_parkees_show_search').serialize(), null, 'script')
    false
  )