$ ->
  assign_accesses_click_actions()
  window.assign_accesses_click_actions = assign_accesses_click_actions
  assign_accesses_search_submit()
  assign_accesses_search_key_up()

assign_accesses_search_submit = () ->
  $('#new_access_search').submit(() ->
    window.accesses_request.abort() if window.accesses_request
    window.accesses_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_accesses_search_key_up = () ->
  $('#new_access_search input').keyup( () ->
    window.accesses_request.abort() if window.accesses_request
    window.accesses_request = $.get($('#new_access_search').attr('action'), $('#new_access_search').serialize(), null, 'script')
    false
  )

assign_accesses_click_actions = (context) ->
  $('#accesses th a, #accesses .pagination a, #new_access_search a', context).off('click')
  $('#accesses th a, #accesses .pagination a, #new_access_search a', context).on('click', () ->
    $.getScript(this.href)
    false
  )
