/**
 * Danish translation for bootstrap-datetimepicker
 * Christian Pedersen <http://github.com/chripede>
 */
;(function($){
    $.fn.datetimepicker.dates['da'] = {
        days: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"],
        daysShort: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"],
        daysMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø", "Sø"],
        months: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
        monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
        today: "I Dag",
        suffix: [],
        meridiem: []
    };
}(jQuery));