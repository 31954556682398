$ ->
  # enable chosen js for locations
  $('.location-chosen-select').each ->
    $(this).chosen
      allow_single_deselect: true
      no_results_text: $(this).data('no-results-text')

  assign_users_click_actions()
  window.assign_users_click_actions = assign_users_click_actions
  assign_users_search_submit()
  assign_users_search_key_up()


assign_users_search_submit = () ->
  $('#new_user_search').submit(() ->
    window.users_request.abort() if window.users_request
    window.users_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_users_search_key_up = () ->
  $('#new_user_search input').keyup( () ->
    window.users_request.abort() if window.users_request
    window.users_request = $.get($('#new_user_search').attr('action'), $('#new_user_search').serialize(), null, 'script')
    false
  )

assign_users_click_actions = () ->
  $('#users th a, #users .pagination a, #new_user_search a').off('click')
  $('#users th a, #users .pagination a, #new_user_search a').on('click', () ->
    $.getScript(this.href)
    false
  )

