/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import Rails from '@rails/ujs';
Rails.start();

require("../stylesheets/application.scss")
require.context('../images', true);


import 'jquery'
import 'popper.js'
import 'chosen-js'
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.da.min'

window.$ = jQuery;
window.jQuery = jQuery;
import './bootstrap-datepicker'
import './bootstrap-datepicker.da'

import './accesses'
import './clickable_row'
import './custom'
import './datepicker'
import './datetimepicker'
import './find_registrations'
import './locations'
import './parkees'
import './registrations'
import './registrations_logs'
import './upload_inputs'
import './users'
