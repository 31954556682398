$ ->
  if $("body.find_registrations").length > 0
    assign_find_registrations_search_submit()
    assign_find_registrations_search_key_up()
    assign_find_registrations_click_actions()
    window.assign_find_registrations_click_actions = @assign_find_registrations_click_actions



@assign_find_registrations_search_submit = () ->
  $('#find_registrations_search').submit(() ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

@assign_find_registrations_search_key_up = () ->
  $('#find_registrations_search input').keyup( () ->
    window.find_registrations_request.abort() if window.find_registrations_request
    window.find_registrations_request = $.get($('#find_registrations_search').attr('action'), $('#find_registrations_search').serialize(), null, 'script')
    false
  )

@assign_find_registrations_click_actions = () ->
  links = $('#registrations th a, #registrations .pagination a, #accesses th a, #accesses .pagination a, #find_registrations_search a')
  links.off('click')
  links.on('click', () ->
    $.getScript(this.href)
    false
  )
