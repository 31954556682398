$ ->
  assign_datepickers()

assign_datepickers = (target = 'body') ->
  $(target).find('div.datepicker').data({behaviour: "datepicker"}).datepicker({
    format: "d/m/yyyy",
    autoclose: true,
    language: $('html').attr('lang'),
    weekStart: 1
  })
