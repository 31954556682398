$ ->
  # enable chosen js for locations
  $('.user-chosen-select').each ->
    $(this).chosen
      allow_single_deselect: true
      no_results_text: $(this).data('no-results-text')

  check_autostop()
  $("input[name='location[registration_setting_attributes][allow_free_choice_of_time_period]']").click(check_autostop)

  check_enabled_modules()
  $("body.locations div.modules .panel-heading input[type='checkbox']").change(check_enabled_modules)

  assign_locations_click_actions()
  window.assign_locations_click_actions = assign_locations_click_actions

  assign_locations_search_submit()
  assign_locations_search_key_up()

check_enabled_modules = () ->
  $("body.locations div.modules .panel-heading input[type='checkbox']").each(() ->
    panel_body = $(this).closest("div.panel").find(".panel-body")
    if $(this).is(':checked')
      panel_body.show(200)
    else
      panel_body.hide(200)
  )

check_autostop = () ->
  if $("input[name='location[registration_setting_attributes][allow_free_choice_of_time_period]']").is(':checked')
    $("input[name='location[registration_setting_attributes][autostop]']").attr("disabled", true)
  else
    $("input[name='location[registration_setting_attributes][autostop]']").removeAttr("disabled")

assign_locations_search_submit = () ->
  $('#new_locations_search').submit(() ->
    window.locations_request.abort() if window.locations_request
    window.locations_request = $.get(this.action, $(this).serialize(), null, 'script')
    false
  )

assign_locations_search_key_up = () ->
  $('#new_location_search input').keyup( () ->
    window.locations_request.abort() if window.locations_request
    window.locations_request = $.get($('#new_location_search').attr('action'), $('#new_location_search').serialize(), null, 'script')
    false
  )

assign_locations_click_actions = (context) ->
  $('#locations th a, #locations .pagination a, #new_location_search a', context).off('click')
  $('#locations th a, #locations .pagination a, #new_location_search a', context).on('click', () ->
    $.getScript(this.href)
    false
  )
